/*
Theme Name: ReactJS With Redux Templat
Author: Bao Tuan Tran
Author URI: https://baholdings.vn
Description: ReactJS With Redux Template
Version: 0.1.0
Tags:

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Alignments
# Layouts
# Header
# Sidebar
# Footer
# Content
	## Pages
	## Posts and Comments
# Media
# Responsive
# Animations
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

@import 'variables';
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-pro/css/all.min.css';
@import '~@fortawesome/fontawesome-pro/css/sharp-solid.min.css';
@import '~@fortawesome/fontawesome-pro/css/sharp-light.min.css';

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

:root {
	--app-padding-top: 6rem;
	--app-padding-bottom: 6rem;
	--app-padding-top-md: 4rem;
	--app-padding-bottom-md: 4rem;
}

body {
	font-family: var(--bs-font-sans-serif);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #0C0011;
	letter-spacing: 3%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	word-wrap: normal;
	font-weight: 700;
	max-width: 100%;
	letter-spacing: 0.36px;
}

p {
	max-width: 100%;
	
	a {
		font-weight: 600;
		color: inherit;

		&:hover, &:focus {
			color: var(--bs-info);
		}
	}
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
a {
	color: inherit;
	text-decoration: none;
}

a, button {
	cursor: pointer;
	outline: none !important;
}

.btn {
	font-weight: 700;
}

a, button, .btn, .dropdown-item, .nav-link {
	i, img {
		margin-right: 8px;
		transition: filter .15s ease;
	}
}

.btn-icon {
	i, img {
		margin-right: 0 !important;
	}
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 8px;
}

.btn-rounded {
	border-radius: 9999px;
}

.btn-link {
	color: inherit;
	padding-left: 0;
	padding-right: 0;
	text-decoration: none !important;

	&:focus {
		box-shadow: none;
	}

	&:hover, &:focus {
		color: var(--bs-info);
	}
}

.btn-primary {
	position: relative;
	z-index: 1;
	color: #FFF !important;
	border-radius: 0;
	border-color: transparent !important;
	background-color: transparent !important;

	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: -3px;
		bottom: -3px;
		left: -3px;
		right: -3px;
		background-color: rgba(255, 255, 255, .2);
		transform: skewX(-20deg);
		border-radius: 12px 22px 12px 26px;
		transition: background-color 0.15s ease-in-out;
	}

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		top: -1px;
		bottom: -1px;
		left: -1px;
		right: -1px;
		background-color: #2569FA;
		transform: skewX(-20deg);
		border-radius: 12px 20px 12px 24px;
		transition: background-color 0.15s ease-in-out;
		border: solid 1.5px rgba(255, 255, 255, .2);
	}

	&:hover {
		&::after {
			background-color: var(--bs-btn-hover-bg);
		}
	}

	&:active {
		&::after {
			background-color: var(--bs-btn-active-bg);
		}
	}
}

// .btn-warning,
// .btn-success,
// .btn-danger {
// 	color: #FFF !important;
// }

.btn-200 {
	min-width: 200px;
	max-width: 100%;
}

ul, ol {
	padding-inline-start: 17px;
}

.dropdown-menu {
	.dropdown-item {
		&:focus {
			background-color: var(--bs-primary);
		}
	}
}

.glowing-icon {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	transform: translateX(-20px);

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		background-color: rgba(255, 255, 255, .4);
		filter: blur(30px);
	}

	img {
		width: 44px;
		height: 44px;
	}
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.form-group {
	&.is-invalid {
		border: 1px solid var(--bs-danger);
		border-radius: var(--bs-border-radius);
	}
}

.input-group {
	.form-control {
		border-right: none;
	}

	.input-group-text {
		margin-left: 0 !important;
		border-left: none;
		background-color: rgba(255, 255, 255, .12);
	}
}

.form-input-with-copy {
	.form-control {
		box-shadow: none !important;
	}
}

.form-image-select {
	position: relative;
	width: 100%;
	max-width: 100%;

	input {
		// width: calc(100% - 1.5rem);
		height: 1px;
		padding: 0;
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
		margin: -1px auto 0;
	}

	.form-label {
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 200px;
		text-align: center;
		background-color: var(--bs-gray-200);
		border-radius: var(--bs-border-radius);
		margin-bottom: 0;
		overflow: hidden;
	}

	.form-image-preview {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--bs-gray-200);

		img {
			object-fit: contain;
		}
	}
}

/*--------------------------------------------------------------
# Navigation
	## Links
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Navigation
	## Menus
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Content
	## Pages
--------------------------------------------------------------*/
.page-header {
	position: relative;
	padding-top: 10rem !important;
	padding-bottom: var(--app-padding-bottom);
	color: var(--bs-white);
	background-color: var(--bs-dark);
	padding-left: 4rem;
	padding-right: 4rem;

	.section-bg {
		z-index: 0 !important;
	}

	.container, .container-xl {
		position: relative;
		z-index: 2;
	}

    .breadcrumb {
        margin-bottom: 1.5rem;
    }

    .sec-header {
        .sec-title {
            font-size: 3rem;
            margin-bottom: 1.5rem;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.16));
        }
    }

	@media (max-width: 991px) {
		padding-top: 8rem !important;

        .sec-header {
            .sec-title {
                font-size: 2rem;
            }
    
            .sec-description {
                font-size: .875rem;
            }
        }
	}

    @media (max-width: 767px) {
        padding-left: 3rem;
        padding-right: 3rem;        
    }
}

.fullscreen-page-header {
    min-height: 100vh;

	.section-bg {
		&.upper-mask {
			bottom: unset;
			height: 40vh;
			background: linear-gradient(180deg, #0C0011 15%, rgba(12, 0, 17, 0.30) 75%, rgba(0, 0, 0, 0.00));
		}
	
		&.lower-mask {
			z-index: 1;
			top: unset;
			height: 30vh;
			background: linear-gradient(0deg, #0C0011 5%, rgba(12, 0, 17, 0.30) 65%, rgba(0, 0, 0, 0.00));
		}
	}

    .breadcrumb {
        margin-bottom: 1.5rem;
    }

    .sec-header {
        .sec-title {
            font-size: 3rem;
            margin-bottom: 1.5rem;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.16));
        }
    }

    @media (max-width: 991px) {
        .sec-header {
            .sec-title {
                font-size: 2rem;
            }
    
            .sec-description {
                font-size: .875rem;
            }
        }
    }
}

/*--------------------------------------------------------------
# Content
	## Sections
--------------------------------------------------------------*/
.page-section {
	position: relative;
	padding-top: var(--app-padding-top);
	padding-bottom: var(--app-padding-bottom);

	@media (max-width: 767px) {
		padding-top: var(--app-padding-top-md);
		padding-bottom: var(--app-padding-bottom-md);
	}

	.section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.sec-header {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}

	.sec-title {
		font-size: 2.25rem;
		font-weight: 700;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: 767px) {
			font-size: 1.5rem;
		}
	}

	.sec-description {
		margin: 0 auto;
		max-width: 748px;
	}

	.sec-badge {
		background: linear-gradient(90deg, #3AA1EC, #26BCAE);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #3AA1EC;
		border-color: #26BCAE;
		margin-bottom: 1rem;
		padding: .375rem 1rem;
	}
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (max-width: 767px) {
	.desktop {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.mobile {
		display: none !important;
	}
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes fadeIn {
	0%      { opacity: 0; }
	100%    { opacity: 1; }
}