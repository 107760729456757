.page-become-a-partner {
    .header-scroll-down {
        top: 21.3rem;
    }

    .header-social {
        top: 12rem;
    }

    .page-header {
        .section-bg {
            &.hero {
                background-position: top center;
            }
        }
        .sec-header {
            @media (min-width: 768px) {
                margin-bottom: 12rem;
            }
        }
    }
}

.market-developer-start-section {
    z-index: 1;

    .bg-blur {
        top: 25%;
        left: -20%;
        bottom: -40%;
        background-position: bottom left;
        background-size: contain;
    }

    .locago-app {
        position: relative;
        background-position: center right;
        background-repeat: no-repeat;
        background-size: contain;

        .locago-app-1 {
            position: absolute;
            z-index: 2;
            bottom: 57%;
            left: 10%;
            width: 36%;
            height: 35%;
            object-fit: contain;
            animation: locago-app-1 5s infinite ease-in-out;
        }

        @keyframes locago-app-1 {
            0%      { transform: translateY(0) rotateZ(0); }
            60%     { transform: translateY(-10%) rotateZ(-2deg); }
            100%    { transform: translateY(0) rotateZ(0); }
        }

        .locago-app-2 {
            position: absolute;
            z-index: 1;
            bottom: 47%;
            right: 33%;
            width: 20%;
            height: 30%;
            object-fit: contain;
            animation: locago-app-2 5s infinite ease-in-out;
        }

        @keyframes locago-app-2 {
            0%      { transform: translateY(0) rotateZ(0); }
            10%     { transform: translateY(0) rotateZ(0); }
            60%     { transform: translateY(-10%) translateX(5%) rotateZ(5deg); }
            100%    { transform: translateY(0) rotateZ(0); }
        }
    }

    .start-list {
        li {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }

    @media (max-width: 767px) {
        .bg-blur {
            top: 0;
            left: -50%;
            bottom: 25%;
        }

        .locago-app {
            height: 460px;
        }
    }

    @media (min-width: 768px) {
        margin-top: -6rem;
        padding: 12rem 0 22rem;

        .locago-app {
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
        }
    }
}

.market-developer-benefits-section {
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
    }

    .container-xl {
        position: relative;
        z-index: 2;
    }

    .why-locanfc-controller {
        .why-locanfc-progress {
            position: relative;
            border-radius: 1rem;
            overflow: hidden;
            height: 4px;
            margin-bottom: 2rem;

            .progress-bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                transition: right .15s ease;

                &.total {
                    right: 0;
                    background: rgba(255, 255, 255, 0.20);
                }

                &.current {
                    background: linear-gradient(90deg, #0094FF 0%, #4DC87E 100%);
                }
            }
        }

        .why-locanfc-controller-item {
            .why-locanfc-item-title {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                cursor: pointer;
    
                h5 {
                    line-height: 1.35;
                    margin: 0;
                }
    
                img {
                    transform: rotate(0);
                    transition: transform .15s ease;
                }
            }
    
            .why-locanfc-item-description {
                display: none;
                margin: 1rem 0 0;
            }
    
            &.active {
                .why-locanfc-item-title {
                    img {
                        transform: rotate(180deg);
                    }
                }
                .why-locanfc-item-description {
                    display: block;
                }
            }
    
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    .why-locanfc-slider-wrapper {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            height: 180px;
            background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
        }
    }

    @media (min-width: 768px) {
        .why-locanfc-slider-controller-wrapper {
            min-height: 480px;
        }
    }
}

.market-partner-conditions-section {
    .section-bg {
        &.wave-1 {
            left: -15%;
            right: 65%;
            background-position: top right;
            background-size: contain;
        }

        &.wave-2 {
            top: 30%;
            right: -20%;
            left: 65%;
            background-position: top left;
            background-size: contain;
        }
    }

    .partner-condition-item {
        display: flex;
        flex-direction: row;
        padding: 1.5rem;
        padding-left: 0;
        border-radius: 0.75rem;
        border: 1px solid var(--bs-primary);
        background: linear-gradient(90deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.20) 100%);
        backdrop-filter: blur(10px);

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        .condition-number {
            width: 200px;
            flex-shrink: 0;
            font-size: 16rem;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            background: linear-gradient(30deg, #0FF 2.36%, #0056DE 95.7%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transform: translateY(-2rem);
        }

        .condition-content {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            flex-grow: 1;
        }

        .condition-title {
            margin: 0;
        }

        .condition-item-1 {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
                font-size: 1.5rem;
                margin: 0;
            }

            p {
                margin: 0;
            }
        }

        .condition-item-2 {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
                font-size: 3rem;
                margin: 0;
            }

            p {
                margin: 0;
            }
        }
    }

    @media (max-width: 767px) {
        .partner-condition-item {
            position: relative;
            flex-direction: column;
            padding: 1.5rem;

            &:not(:last-child) {
                margin-bottom: 5rem;
            }

            .condition-number {
                position: absolute;
                top: -2rem;
                right: 1rem;
                width: unset;
                font-size: 6rem;
                text-align: right;
            }

            .col-md-4 {
                &:not(:last-child) {
                    .condition-item-1,
                    .condition-item-2 {
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }
    }
}

.contact-info-item {
    padding: 1.25rem 1.5rem 2rem;
    background-color: rgba(255, 255, 255, .12);
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 1rem;
}

.send-message-section {
    .col-md-5 {
        position: relative;
    }
}