.about-transformation-section {
    z-index: 1;

    .transformation-section-bg {
        top: 6rem;
        bottom: 6rem;
        background-size: contain;
    }

    .transformation-section-overlay {
        z-index: 1;
        mix-blend-mode: screen;
        top: -25%;
        bottom: -20%;
        left: -40%;
        right: -35%;
        background-size: auto 100%;
    }

    .transformation-wrapper {
        position: relative;
        z-index: 0;
        padding: 9rem 2rem 14rem;
        border-radius: 0.75rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(186, 184, 184, 0.10) 100%);
        backdrop-filter: blur(10px);

        .locago-app {
            left: 40%;
            background-position: bottom right;
            background-size: contain;
        }

        .locago-app-noti {
            top: 64%;
            left: 78%;
            right: unset;
            width: 380px;
            background-size: contain;
            // filter: drop-shadow(0 8px 32px rgba(0, 148, 255, .5));
        }

        .coin-top-right {
            z-index: 1;
            top: -95px;
            bottom: unset;
            left: unset;
            right: -115px;
            width: 200px;
            height: 220px;
        }

        .coins-bottom-left {
            z-index: 1;
            top: unset;
            bottom: -104px;
            left: -170px;
            right: unset;
            width: 392px;
            height: 220px;
        }

        .row {
            position: relative;
            z-index: 2;
        }

        @media (max-width: 767px) {
            padding: 2rem 2rem 24rem;

            .locago-app {
                top: 48%;
                left: 0;
            }
        }
    }
}

.about-vision-roadmap-wrapper {
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
    }

    .page-section {
        z-index: 2;
    }
}

.about-vision-section {
    .section-bg {
        background-size: contain;
    }

    .about-vision-row {
        @media (min-width: 768px) {
            .col-md-3 {
                &:nth-child(odd) {
                    .about-vision-item {
                        margin-bottom: 20%;
                    }
                }
    
                &:nth-child(even) {
                    .about-vision-item {
                        margin-top: 30%;
                    }
                }
            }
            
            .about-vision-item {
                margin-bottom: 0;
            }
        }
    }

    .about-vision-item {
        text-align: center;
        padding: 1.5rem .75rem;
        border-radius: 0.75rem;
        border: 1px solid var(--bs-primary);
        background: linear-gradient(90deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.20) 100%);
        backdrop-filter: blur(10px);
        margin-bottom: 1rem;

        &.active {
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        img {
            margin-bottom: 2rem;
        }

        h3 {
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }

        p {
            margin: 0;
        }
    }
}

.about-roadmap-section {
    .about-roadmap-slider {
        .swiper-slide-active {
            .roadmap-item {
                border-color: var(--bs-success);
                background: linear-gradient(90deg, rgba(58, 161, 236, 0.40) 0%, rgba(38, 188, 174, 0.40) 100%);
            }
        }

        .swiper-pagination {
            margin-top: 1rem;
        }

        .roadmap-item {
            position: relative;
            height: 330px;
            padding: 1.5rem;
            border-radius: 0.75rem;
            border: 1px solid var(--bs-primary);
            background: linear-gradient(90deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.20) 100%);
            margin-top: calc(1rem + 56px);

            @media (max-width: 767px) {
                height: 380px;
            }

            .roadmap-item-index {
                position: absolute;
                z-index: 1;
                bottom: calc(100% + 1rem);
                left: 8px;
                width: 56px;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                font-weight: 700;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(178, 178, 178, 0.00) 100%);
                border-radius: 50%;

                &::after {
                    content: '';
                    position: absolute;
                    top: 48px;
                    left: 27.5px;
                    z-index: 2;
                    width: 1px;
                    height: 50px;
                    background-color: #FFF;
                }
            }

            .roadmap-item-title {
                font-size: 1.5rem;
                text-transform: uppercase;
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                &::before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background: var(--gradient, linear-gradient(90deg, #0094FF 0%, #4DC87E 100%));
                    border: 4px solid rgba(255, 255, 255, 1);
                    margin-top: 2px;
                    margin-right: .5rem;
                }
            }
        }
    }
}

.about-team-section {
    .section-bg {
        top: 10rem;
        bottom: 3rem;
        background-size: contain;
        opacity: .5;
    }

    .team-slider {
        .swiper-wrapper {
            padding-top: 5rem;
        }
        .swiper-slide-active {
            .team-item {
                transform: translateY(-5rem);

                .team-item-content {
                    opacity: 1;
                }

                img {
                    opacity: 1;
                }
            }
        }
        
        .swiper-slide-prev,
        .swiper-slide-next {
            .team-item {
                transform: translateY(-3rem);

                img {
                    opacity: .8;
                }
            }
        }
        
        .team-item {
            position: relative;
            border-radius: .75rem;
            overflow: hidden;
            background: rgba(12, 0, 17, .2);
            border: 3px solid rgba(255, 255, 255, 0.20);
            backdrop-filter: blur(25px);
            transition: transform .15s ease;

            img {
                width: auto;
                height: 430px;
                object-fit: cover;
                opacity: .6;
                transition: opacity .15s ease;
            }

            .team-item-content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: .75rem;
                text-align: center;
                background: rgba(255, 255, 255, 0.20);
                backdrop-filter: blur(25px);
                opacity: 0;
                transition: opacity .3s ease;

                .team-item-name {
                    font-size: 1.5rem;
                    margin-bottom: .25rem;
                }

                .team-item-position {
                    font-size: 1.25rem;
                    font-weight: 500;
                    margin: 0;
                    background: var(--gradient, linear-gradient(90deg, #0094FF 0%, #4DC87E 100%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}