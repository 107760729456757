.page-features {
    .header-scroll-down {
        top: 20rem;
    }
    
    @media (max-width: 767px) {
        .header-scroll-down {
            display: none;
        }
    }
}

.features-page-header {
    .features-header-blur {
        top: -30%;
        left: 60%;
        right: -30%;
        background-size: auto 100%;
    }
}

.features-galaxy-section {
    padding-bottom: 26rem;

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
    }

    .section-bg {
        background-position: top center;
    }

    .features-galaxy {
        position: relative;

        .features-galaxy-img {
            mix-blend-mode: screen;
        }

        .locago-coin {
            position: absolute;
            top: calc(50% - 152px);
            left: calc(50% - 135px);
            width: 270px;
            height: 292px;

            @media (max-width: 767px) {
                top: calc(50% - 116px);
                left: calc(50% - 100px);
                width: 200px;
                height: 216px;
            }
        }

        .feature-link {
            position: absolute;
            display: flex;
            flex-direction: column;

            .feature-link-text {
                font-weight: 700;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                transition: all .15s ease;

                @media (min-width: 768px) {
                    white-space: nowrap;
                }
            }

            .feature-link-icon {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 64px;
                height: 64px;
                border-radius: 50%;
                overflow: hidden;
                background-color: rgba(255, 255, 255, 0.20);
                backdrop-filter: blur(10px);

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(90deg, #0094FF 0%, #4DC87E 100%);
                    opacity: 0;
                    transition: opacity .15s ease;
                }

                img {
                    position: relative;
                    z-index: 2;
                    width: 48px;
                    height: 48px;
                    margin: 0;
                }
            }

            &:hover, &:active, &.active {
                .feature-link-text {
                    background: linear-gradient(90deg, #0094FF 0%, #4DC87E 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .feature-link-icon {
                    &::before {
                        opacity: 1;
                    }
                }
            }

            &.for-users {
                bottom: 93%;
                left: calc(50% - 70px);
                align-items: center;

                .feature-link-text {
                    margin-bottom: .75rem;
                }
            }

            &.for-businesses {
                top: 68%;
                right: 72%;
                align-items: flex-end;

                .feature-link-text {
                    padding-right: 64px;
                }
            }

            &.for-owners {
                top: 68%;
                left: 72%;
                align-items: flex-start;

                .feature-link-text {
                    padding-left: 64px;
                }
            }

            @media (max-width: 767px) {
                &.for-users {
                    bottom: 90%;
                }

                &.for-businesses {
                    top: 66%;
                    right: 70%;

                    .feature-link-text {
                        text-align: right;
                        margin-top: .5rem;
                        padding-right: 0;
                    }
                }

                &.for-owners {
                    top: 66%;
                    left: 70%;

                    .feature-link-text {
                        margin-top: .5rem;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.membership-package-slider {
    .swiper-wrapper {
        align-items: center;
    }

    .membership-package {
        position: relative;
        padding: 1.5rem;
        border-radius: 0.75rem;
        border: 1px solid var(--bs-primary);
        background: linear-gradient(40deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.30));
        overflow: hidden;

        .section-bg {
            opacity: 0;
            transition: opacity .35s ease;
        }

        .membership-package-price {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            gap: .25rem;
            margin-bottom: 1rem;

            h3, p {
                margin: 0;
            }
        }

        .membership-package-body {
            ul {
                list-style: none;
                padding-inline-start: 0;

                li {
                    position: relative;
                    padding-left: 28px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        background: url('../../../assets/images/membership-package-list-icon.svg') center no-repeat;
                        background-size: contain;
                    }

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .membership-package-footer {
            display: none;
            padding-top: 1rem;
        }
    }

    .swiper-slide-active {
        .membership-package {
            .section-bg {
                opacity: 1;
            }

            .membership-package-footer {
                display: block;
            }
        }
    }
}

.features-for-users-page-header {
    .features-for-users-header-bg {
        background-position: bottom center;
    }

    .features-for-users-header-slider-wrapper {
        position: absolute;
        z-index: 2;
        bottom: 18.5%;
        left: 0;
        right: 0;
    }

    .features-for-users-header-slider {
        .for-users-header-slide-item {
            width: 100%;
            height: 350px;
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .swiper-slide-shadow-cube {
            opacity: .5;
        }

        .swiper-cube-shadow {
            opacity: .4;
        }
    }

    .features-for-users-header-thumbs-wrapper {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;

        .section-bg {
            background-position: bottom center;
            transform: translateY(-70px);
        }
    }

    .features-for-users-header-thumbs {
        .for-users-header-thumb-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            transition: margin .15s ease;

            .thumb-icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 64px;
                background-color: rgba(255, 255, 255, 0.20);
                backdrop-filter: blur(10px);
                border-radius: 50%;
                overflow: hidden;

                &::before {
                    content: '';
                    opacity: 0;
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(90deg, #0094FF 0%, #4DC87E 100%);
                    backdrop-filter: blur(10px);
                    transition: opacity .15s ease;
                }

                .thumb-icon-active {
                    display: none;
                }
            }
        }

        .swiper-slide-active {
            .for-users-header-thumb-item {
                margin-top: 15%;

                .thumb-icon {
                    &::before {
                        opacity: 1;
                    }

                    .thumb-icon-active {
                        display: block;
                    }
                    
                    .thumb-icon-inactive {
                        display: none;
                    }
                }
            }
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            .for-users-header-thumb-item {
                margin-top: 10%;
            }
        }
    }
}

.features-for-users-why-section {
    .section-bg {
        background-position: top right;

        @media (min-width: 768px) {
            left: -20%;
            right: 28%;
            background-size: contain;
        }
    }

    .why-wrapper {
        padding: 2rem;
        border-radius: 0.75rem;
        background: linear-gradient(90deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.20) 100%);
        backdrop-filter: blur(10px);

        @media (max-width: 767px) {
            padding: 2rem .75rem;
        }
    }
}

.features-for-users-earn-section {
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
    }

    .earn-bg {
        background-size: auto 100%;
    }

    .locago-app {
        left: 43%;
        background-position: bottom;
        background-size: contain;
    }

    .container-xl {
        position: relative;
        z-index: 2;
    }

    ul {
        list-style: none;
        padding-inline-start: 0;

        li {
            position: relative;
            padding-left: 17px;

            &::before {
                content: '';
                position: absolute;
                top: 7.5px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--bs-primary);
            }

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 0;

        &::after {
            z-index: 3;
            height: 80px;
        }
    }
}

.features-for-users-weight-section {
    padding: 0;

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
    }

    .locago-coins {
        background-size: contain;
    }

    .container-xl {
        position: relative;
        z-index: 2;
        padding-top: 18rem;
        padding-bottom: 18rem;
    }
}

.features-for-users-packages-section {
    .blur-bg {
        left: 60%;
        right: -20%;
        background-position: top left;
        background-size: contain;
    }
}

.features-for-users-promo-section {
    .section-bg {
        background-size: contain;
        top: -5%;
        left: -20%;
        right: 50%;
        bottom: 30%;
        opacity: .3;
    }

    .sec-content {
        position: relative;
        
        @media (min-width: 768px) {
            padding-top: 110px;
        }
    }

    .promo-img {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        border-bottom-right-radius: 1.5rem;
    }

    .promo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 1.5rem;
        overflow: hidden;
        background: var(--gradient, linear-gradient(90deg, rgba(0, 148, 255, .2) 0%, rgba(77, 200, 126, .2) 100%));
    }

    .promo-body {
        padding: 8rem 3.75rem 10rem;
        overflow: hidden;
    }

    .promo-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        border-radius: 0rem 0rem 1.5rem 1.5rem;
        background: rgba(255, 255, 255, 0.10);
        box-shadow: 0px -1px 16px 0px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(100px);
        padding: .75rem 3.75rem;

        p {
            font-weight: 600;
            letter-spacing: .2rem;
            margin: 0;
            text-transform: uppercase;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        img {
            width: auto;
            height: 48px;
        }
    }

    @media (max-width: 767px) {
        .promo-body {
            padding: 2.75rem .75rem;

            img {
                margin-bottom: -96px;
            }
        }

        .promo-footer {
            padding: .75rem;

            p {
                font-size: .75rem;
            }

            img {
                width: auto;
                height: 24px;
            }
        }
    }
}

.features-for-businesses-page-header {
    .features-for-businesses-header-bg {
        background-position: top right;
    }

    .sec-header {
        margin-bottom: 9rem;
    }

    @media (max-width: 767px) {
        .features-for-businesses-header-bg {
            top: 50%;
            &::before {
                content: '';
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                right: 0;
                height: 80px;
                background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
            }
        }

        .sec-header {
            margin-bottom: 3rem;
        }
    }
}

.features-for-businesses-locanfc-section {
    z-index: 1;

    .bg-blur {
        top: 25%;
        left: -20%;
        bottom: -40%;
        background-position: bottom left;
        background-size: contain;
    }

    .locago-app {
        position: relative;
        background-position: center right;
        background-repeat: no-repeat;
        background-size: contain;

        .locago-app-1 {
            position: absolute;
            z-index: 2;
            bottom: 57%;
            left: 10%;
            width: 36%;
            height: 35%;
            object-fit: contain;
            animation: locago-app-1 5s infinite ease-in-out;
        }

        @keyframes locago-app-1 {
            0%      { transform: translateY(0) rotateZ(0); }
            60%     { transform: translateY(-10%) rotateZ(-2deg); }
            100%    { transform: translateY(0) rotateZ(0); }
        }

        .locago-app-2 {
            position: absolute;
            z-index: 1;
            bottom: 47%;
            right: 33%;
            width: 20%;
            height: 30%;
            object-fit: contain;
            animation: locago-app-2 5s infinite ease-in-out;
        }

        @keyframes locago-app-2 {
            0%      { transform: translateY(0) rotateZ(0); }
            10%     { transform: translateY(0) rotateZ(0); }
            60%     { transform: translateY(-10%) translateX(5%) rotateZ(5deg); }
            100%    { transform: translateY(0) rotateZ(0); }
        }
    }

    @media (max-width: 767px) {
        .bg-blur {
            top: 0;
            left: -50%;
            bottom: 25%;
        }

        .locago-app {
            height: 460px;
        }
    }

    @media (min-width: 768px) {
        margin-top: -6rem;
        padding: 12rem 0 22rem;

        .locago-app {
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
        }
    }
}

.features-for-businesses-why-section {
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
    }

    .container-xl {
        position: relative;
        z-index: 2;
    }

    .why-locanfc-controller {
        .why-locanfc-progress {
            position: relative;
            border-radius: 1rem;
            overflow: hidden;
            height: 4px;
            margin-bottom: 2rem;

            .progress-bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                transition: right .15s ease;

                &.total {
                    right: 0;
                    background: rgba(255, 255, 255, 0.20);
                }

                &.current {
                    background: linear-gradient(90deg, #0094FF 0%, #4DC87E 100%);
                }
            }
        }

        .why-locanfc-controller-item {
            .why-locanfc-item-title {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                cursor: pointer;
    
                h5 {
                    line-height: 1.35;
                    margin: 0;
                }
    
                img {
                    transform: rotate(0);
                    transition: transform .15s ease;
                }
            }
    
            .why-locanfc-item-description {
                display: none;
                margin: 1rem 0 0;
            }
    
            &.active {
                .why-locanfc-item-title {
                    img {
                        transform: rotate(180deg);
                    }
                }
                .why-locanfc-item-description {
                    display: block;
                }
            }
    
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    .why-locanfc-slider-wrapper {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            height: 180px;
            background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
        }
    }

    @media (min-width: 768px) {
        .why-locanfc-slider-controller-wrapper {
            min-height: 780px;
        }
    }
}

.features-for-owners-page-header {
    .features-for-owners-header-bg {
        background-position: top right;
    }

    .features-for-owners-wave {
        &.desktop {
            z-index: 1 !important;
            top: 45%;
            left: 40%;
            background-size: contain;
        }

        &.mobile {
            display: block;
            margin-left: -2rem;
            margin-right: -2rem;
            width: calc(100% + 4rem);
        }
    }

    .sec-header {
        margin-bottom: 16rem;
    }

    @media (max-width: 767px) {
        .features-for-owners-header-bg {
            top: 50%;

            &::before {
                content: '';
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                right: 0;
                height: 80px;
                background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
            }
        }

        .features-for-owners-wave {
            top: 48%;
        }

        .sec-header {
            margin-bottom: 3rem;
        }
    }
}

.features-for-owners-why-section {
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(to top, rgba(9, 17, 30, 0), #0C0011);
    }

    .section-bg {
        background-position: top center;
    }

    .container-xl {
        position: relative;
        z-index: 2;
    }

    .why-locanfc-controller {
        .why-locanfc-progress {
            position: relative;
            border-radius: 1rem;
            overflow: hidden;
            height: 4px;
            margin-bottom: 2rem;

            .progress-bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                transition: right .15s ease;

                &.total {
                    right: 0;
                    background: rgba(255, 255, 255, 0.20);
                }

                &.current {
                    background: linear-gradient(90deg, #0094FF 0%, #4DC87E 100%);
                }
            }
        }

        .why-locanfc-controller-item {
            .why-locanfc-item-title {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                cursor: pointer;
    
                h5 {
                    line-height: 1.35;
                    margin: 0;
                }
    
                img {
                    transform: rotate(0);
                    transition: transform .15s ease;
                }
            }
    
            .why-locanfc-item-description {
                display: none;
                margin: 1rem 0 0;
            }
    
            &.active {
                .why-locanfc-item-title {
                    img {
                        transform: rotate(180deg);
                    }
                }
                .why-locanfc-item-description {
                    display: block;
                }
            }
    
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    .why-locanfc-slider-wrapper {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            height: 180px;
            background: linear-gradient(to bottom, rgba(9, 17, 30, 0), #0C0011);
        }
    }

    @media (min-width: 768px) {
        .why-locanft-slider-controller-wrapper {
            min-height: 480px;
        }
    }
    
    @media (max-width: 767px) {
        padding-bottom: 28rem;

        .section-bg {
            top: 55%;
            background-position: bottom right;
        }
    }
}