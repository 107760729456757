@import 'assets/scss/variables.scss';

.page-home {
    @media (max-width: 767px) {
        .header-social {
            display: none;
        }
    }
}

.home-header {
    padding-top: 10rem;
    padding-bottom: 0;

    .upper-mask {
        left: 70%;
        background-position: top right;
        background-size: 100%;
    }

    .lower-mask {
        z-index: 1;
        top: 70%;
        background: linear-gradient(0deg, #0C0011 5%, rgba(12, 0, 17, 0.30) 65%, rgba(0, 0, 0, 0.00));
    }

    .sec-header {
        .sec-title {
            font-size: 8.75rem;
            margin-bottom: 4.5rem;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.16));
        }

        .sec-description {
            font-size: 3.5rem;
            font-weight: 500;
            color: #FFF;
        }
    }

    .header-earth {
        position: relative;
        z-index: 0;
        height: 100vh;
        margin-top: -48vh;
    }

    @media (min-width: 992px) {
        min-height: 100vh;
    }

    @media (max-width: 991px) {
        padding-top: 8rem;

        .upper-mask {
            left: 30%;
        }

        .sec-header {
            .sec-title {
                font-size: 5.25rem;
                margin-bottom: 4.5rem;
            }
    
            .sec-description {
                font-size: 2rem;
            }
        }

        .header-earth {
            height: 90vh;
        }
    }
}

.video-section {
    .bottom-left {
        right: 65%;
        top: 60%;
        bottom: -30%;
        background-position: top right;
        background-size: auto 100%;
    }
    .video-frame {
        border: 6px solid #919191;
        border-radius: 0 0 1rem 1rem;
        overflow: hidden;
    }
}

.mission-section {
    .vr-man {
        width: 760px;
        top: 150px;
        left: calc(50% - 380px);
        right: calc(50% - 380px);
        background-position: top center;
        background-size: 100%;
    }

    .middle-right {
        top: 20%;
        left: 65%;
        background-position: center left;
        background-size: auto 100%;
    }

    .mission-group {
        p {
            margin-bottom: 0;
        }
    }

    @media (min-width: 768px) {    
        .sec-header {
            margin-bottom: 12rem;
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 24rem;

        .vr-man {
            width: 500px;
            left: calc(50% - 250px);
            right: calc(50% - 250px);
            background-position: center calc(100% - 2rem);
        }

        .middle-right {
            background-position: top left;
            left: 50%;
        }

        .mission-group {
            h3 {
                font-size: 1rem;
            }

            p {
                font-size: .75rem;
            }
        }
    }
}

.mission2-section {
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(to bottom, #0C0011 0%, rgba(5, 0, 8, 0.30) 55.1%, rgba(0, 0, 0, 0.00) 100%);
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        background: linear-gradient(0deg, #0C0011 0.4%, rgba(5, 0, 8, 0.30) 55.04%, rgba(0, 0, 0, 0.00) 99.56%);
    }
}

.building-section {
    .sec-header {
        margin-bottom: 6rem;
    }

    .building-maps {
        position: relative;

        .building-map-pin {
            position: absolute;
            width: 225px;
            filter: drop-shadow(0 4px 8px rgba(0, 0, 0, .35));

            .building-pin {
                width: 34px;
                height: 48px;
                cursor: pointer;
            }

            .building-message {
                position: absolute;
                bottom: 56px;
                left: -20px;
                transition: opacity 0.15s ease;
            }

            &.pin-1 {
                bottom: 86%;
                left: calc(24.8% - 17px);
            }

            &.pin-2 {
                bottom: 75%;
                left: calc(70.8% - 17px);

                .building-message {
                    opacity: 1;
                }
            }

            &.pin-3 {
                bottom: 26.5%;
                left: calc(62.2% - 17px);
            }

            @media (min-width: 768px) {
                .building-message {
                    opacity: 0;
                }

                &:hover {
                    .building-message {
                        opacity: 1;
                    }
                }
            }

            @media (max-width: 767px) {
                width: 148px;

                .building-message {
                    bottom: 28px;
                    left: -15px;
                }

                .building-pin {
                    width: 18px;
                    height: 24px;
                }

                &.pin-1 {
                    left: calc(24.8% - 9px);
                }
    
                &.pin-2 {
                    bottom: 86%;
                    left: calc(55.8% - 9px);
                }
    
                &.pin-3 {
                    left: calc(62.2% - 9px);
                }
            }
        }
    }
}

.rewards-section {
    padding-top: 10rem;
    padding-bottom: 6rem;

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 210px;
        background: linear-gradient(to bottom, #0C0011 5%, rgba(5, 0, 8, 0.30) 55.1%, rgba(0, 0, 0, 0.00) 100%);
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 260px;
        background: linear-gradient(0deg, #0C0011 5%, rgba(5, 0, 8, 0.30) 55.04%, rgba(0, 0, 0, 0.00) 99.56%);
    }

    .top-right {
        z-index: 1;
        left: 65%;
        top: -30%;
        bottom: 60%;
        background-position: top left;
        background-size: auto 100%;
    }

    .reward-grid {
        .reward-item {
            height: 100%;
            padding: .75rem;
            border-radius: 1.5rem;
            border: 1px solid #3AA1EC;
            background: linear-gradient(90deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.20) 100%);
            backdrop-filter: blur(10px);

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
            
            .reward-item-img {
                width: 100%;
                height: 100%;
                min-height: 140px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 1rem;
            }
        }

        @media (min-width: 768px) {
            .col-md-2 {
                padding-top: 6%;
                padding-bottom: 6%;
            }
        }

        @media (max-width: 767px) {
            .reward-item {
                height: unset;
                margin-bottom: .75rem !important;

                .reward-item-img {
                    min-height: 250px;
                }
            }
        }
    }
}

.rewards2-section {
    z-index: 1;

    .wave {
        top: -12rem;
        right: 50%;
        background-position: top right;
        background-size: auto 100%;
    }

    .blur-light {
        top: -10%;
        bottom: -50%;
        left: -80%;
        right: 60%;
        background-position: top right;
        background-size: auto 100%;
    }

    .container-xl {
        position: relative;
        
        @media (min-width: 768px) {
            padding-top: 110px;
        }
    }

    .rewards-image {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 30%;
        right: -10%;

        img {
            height: 100%;
            width: auto;
            object-fit: contain;
        }
    }

    .rewards-wrapper {
        padding: 9rem 3.75rem;
        border-radius: 0.75rem;
        background: linear-gradient(90deg, rgba(58, 161, 236, .2) 0%, rgba(38, 188, 174, .2) 100%);
        // backdrop-filter: blur(10px);
        overflow: hidden;
    }

    @media (max-width: 767px) {
        .rewards-wrapper {
            padding: 4rem 1rem 1rem;
        }
    }
}

.bussiness-support-section {
    .blur-light {
        top: -20%;
        background-position: top left;
    }
}

.about-section {
    .section-bg {
        background-position: top center;
        background-size: 70%;
    }

    .about-item {
        height: 100%;
        padding: 1rem;
        border-radius: .75rem;
        background: rgba(47, 49, 55, 0.50);
        border: 1px solid rgba(47, 49, 55, 0.50);
        box-shadow: 0px 2px 16px 0px rgba(33, 34, 38, 0.60);
        backdrop-filter: blur(15px);
        transition: all .15s ease;

        &:hover {
            border-color: var(--bs-primary);
            box-shadow: 0px 2px 16px 0px rgba(33, 34, 38, 0.60), 0px 0px 20px 0px #184285;
        }

        h3 {
            font-size: 1.125rem;
            margin-top: 1.5rem;
            margin-bottom: .75rem;
        }

        p {
            color: #9195A1;
            margin-bottom: 0;
        }
    }
}

.explore-section {
    padding-top: 2rem;

    .section-bg {
        top: 210px;
        background-position: bottom center;
    }

    .sec-header {
        @media (min-width: 768px) {
            margin-bottom: 10rem;
        }
    }

    h3 {
        span {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .hotspot-features {
        @media (min-width: 768px) {
            position: absolute;
            bottom: 100%;
            left: -35%;
            width: 100%;
        }

        .hotspot-feature-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: .75rem;
            border-radius: 8px;
            background: rgba(47, 49, 55, 0.50);
            box-shadow: 0px 2px 16px 0px rgba(33, 34, 38, 0.60);
            backdrop-filter: blur(15px);

            &:not(:first-child) {
                margin-top: .5rem;
            }

            img {
                margin-right: .75rem;
            }
        }
    }
}

.ecosystem-section {
    .ecosystem-col {
        &:nth-child(even) {
            .ecosystem-item {
                background: linear-gradient(180deg, rgba(6, 102, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--secondary, #2F3137);
            }
        }
    }
    .ecosystem-item {
        height: 100%;
        background-color: var(--bs-secondary);
        padding: 2rem 1rem;
        border-radius: .75rem;
        overflow: hidden;

        h3 {
            font-size: 1.25rem;

            img {
                vertical-align: -17px;
            }

            span {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            color: #9195A1;
            margin-bottom: 0;
        }

        .ecosystem-nfts-img {
            height: 100%;
            min-height: 400px;

            .section-bg {
                @media (max-width: 767px) {
                    background-position: top center;
                }
            }
        }
    }
}

.roadmap-section {
    .roadmap-section-wrapper {
        position: relative;

        @media (min-width: 768px) {
            padding: 4rem;
            border-radius: 1.5rem;
            background: rgba(47, 49, 55, 0.30);
        }
    }

    .roadmap {
        position: relative;

        .roadmap-line {
            position: absolute;
            top: 0;
            bottom: 0;
            left: calc(50% - 0.5px);
            width: 1px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0));

            @media (max-width: 767px) {
                left: 8px;
            }
        }

        .roadmap-item {
            position: relative;
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
                padding-left: 2rem;
            }

            @media (min-width: 768px) {
                &:nth-child(even) {
                    margin-left: auto;
                    padding-left: 3rem;
                }
    
                &:nth-child(odd) {
                    margin-right: auto;
                    padding-right: 3rem;
    
                    .roadmap-item-content {
                        &::before {
                            left: unset;
                            right: calc(-3rem - 8px);
                        }
                    }
                }
            }

            .roadmap-item-year {
                font-size: 6rem;
                font-weight: 700;
                background: linear-gradient(90deg, #8CB7FB 0%, #2377FC 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            
            .roadmap-item-content {
                margin-top: -67px;
                padding: .75rem;
                border-radius: .5rem;
                background: rgba(47, 49, 55, 0.50);
                box-shadow: 0px 2px 16px 0px rgba(33, 34, 38, 0.60);
                backdrop-filter: blur(15px);

                &::before {
                    content: '';
                    position: absolute;
                    top: calc(50% - 8px);
                    left: calc(-3rem - 8px);
                    width: 16px;
                    height: 16px;
                    background: linear-gradient(50deg, rgba(90, 65, 189, 0.00) -13.13%, #2931F2 106.07%), linear-gradient(0deg, var(--bs-primary) 0%, var(--bs-primary) 100%), var(--primary, #2377FC);
                    filter: drop-shadow(0px 0px 12px #2B42D1);
                    border-radius: 50%;

                    @media (max-width: 767px) {
                        left: -2rem;
                    }
                }
            }

            .roadmap-item-title {
                font-size: 1.125rem;
            }

            ul {
                margin-bottom: 0;
                color: #9195A1;
            }
        }
    }
}

.partners-section {
    .partner-item {
        position: relative;
        // padding: 1.25rem;
        text-align: center;
        border-radius: .75rem;
        background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 1));

        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 1px;
            right: 1px;
            background-color: #25272B;
            border-radius: .75rem;
        }

        img {
            position: relative;
            z-index: 1;
        }
    }
}

.team-section {
    .team-member-card-wrapper {
        height: 100%;
    }
    .team-member-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: .5rem;
        overflow: hidden;
        
        .team-member-card-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            flex-grow: 1;
            min-height: 450px;
        }

        .team-member-card-detail {
            padding: 2.5rem 2rem;
            background: rgba(47, 49, 55, 0.50);
            border: 1px solid rgba(47, 49, 55, 0.50);

            p {
                margin: 0;
                color: #CCC;
            }
        }
    }

    .team-member {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        background: rgba(47, 49, 55, 0.50);
        border-radius: .75rem;
        border: 1px solid rgba(47, 49, 55, 0.50);
        cursor: pointer;
        transition: border-color 350ms ease;
        backdrop-filter: blur(15px);

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }

        &.active {
            border-color: var(--bs-primary);
            box-shadow: 0px 2px 16px 0px rgba(33, 34, 38, 0.60), 0px 0px 20px 0px #184285;
        }

        .team-member-img {
            width: 100px;
            height: 100px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 1rem;
            border-radius: .5rem;
        }

        p {
            margin: 0;
        }
    }
}

.contact-section {
    .contact-section-wrapper {
        position: relative;
        padding: 4rem;
        border-radius: 1.5rem;
        background: linear-gradient(180deg, rgba(6, 102, 255, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%), var(--bs-secondary);

        @media (max-width: 767px) {
            padding: 2rem;
        }

        .section-bg {
            z-index: 0;
            left: 50%;
            background-position: top right;
            background-size: contain;
        }
    }
}

.news-section {
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -70px;
        left: -70px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background: radial-gradient(#274EE1, #AE93F3 40%, #42A4C5);
        filter: blur(100px);
    }
    
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: -70px;
        right: -70px;
        width: 230px;
        height: 230px;
        border-radius: 50%;
        background: radial-gradient(#274EE1, #AE93F3 40%, #42A4C5);
        filter: blur(100px);
    }

    .article {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid #FFF;
        border-radius: 1rem;
        overflow: hidden;
        color: #FFF;
        background-color: rgba(255, 255, 255, .12);
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .05));
        transition: background-color .15s ease;

        &:hover {
            background-color: rgba(14, 130, 234, .4);

            img {
                transform: scale(1.05);
            }
        }
    
        .article-image {
            position: relative;
            z-index: 1;
            height: 200px;
            overflow: hidden;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .25s ease;
            }
        }
    
        .article-info {
            padding: 1.5rem 1rem;
    
            .article-title {
                font-size: 1.5rem;
                margin-bottom: .75rem;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .article-description {
                margin-bottom: 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.what-is-locanft-section {
    .locanft-maps-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .locanft-maps-animation {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                z-index: 0;
                top: 0;
                bottom: 0;
                left: -8%;
                right: -8%;
                background: linear-gradient(to bottom, #0C0011, rgba(5,0,8,.3) 15%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(5,0,8,.3) 85%, #0C0011);
            }
    
            &::after {
                content: '';
                position: absolute;
                z-index: 0;
                top: 0;
                bottom: 0;
                left: -8%;
                right: -8%;
                background: linear-gradient(to right, #0C0011, rgba(5,0,8,.3) 8%,  rgba(0,0,0,0) 15%, rgba(0,0,0,0) 85%, rgba(5,0,8,.3) 92%, #0C0011);
            }
        }

        .locanft-maps-bg {
            margin-left: -8%;
            margin-right: -8%;
        }
    
        .locanft-maps-elements {
            position: absolute;
            z-index: 1;
            top: 30.2%;
            left: 11%;
            right: 25%;

            .nav-arrow {
                position: absolute;
                left: -2%;
                top: 92%;
                width: 7%;
                height: 6.5%;
                transform: rotateZ(51deg);
                animation: nav-arrow 15s 2s infinite ease;
            }

            @keyframes nav-arrow {
                0%      { left: -2%; top: 92%; transform: rotateZ(51deg); }
                5%      { left: 1%; top: 97%; transform: rotateZ(51deg); }
                6%      { left: 1%; top: 97%; transform: rotateZ(0deg); }
                8%      { left: 9%; top: 95%; transform: rotateZ(0deg); }
                9%      { left: 9%; top: 95%; transform: rotateZ(15deg); }
                10%     { left: 11%; top: 96%; transform: rotateZ(15deg); }
                11%     { left: 13%; top: 95%; transform: rotateZ(-11deg); }
                15%     { left: 30%; top: 85%; transform: rotateZ(-11deg); } // pin-1
                16%     { left: 29.5%; top: 86%; transform: rotateZ(47deg); }
                18%     { left: 31.5%; top: 89%; transform: rotateZ(47deg); }
                19%     { left: 32.5%; top: 88%; transform: rotateZ(-13deg); }
                25%     { left: 60%; top: 79%; transform: rotateZ(-8deg); }
                26%     { left: 59.5%; top: 77%; transform: rotateZ(-98deg); }
                30%     { left: 57.8%; top: 66%; transform: rotateZ(-98deg); }
                31%     { left: 58.8%; top: 66%; transform: rotateZ(-6deg); }
                35%     { left: 72%; top: 61%; transform: rotateZ(-18deg); }
                36%     { left: 71%; top: 59%; transform: rotateZ(-136deg); }
                40%     { left: 68.5%; top: 55%; transform: rotateZ(-136deg); }
                41%     { left: 69.5%; top: 56%; transform: rotateZ(-17deg); }
                45%     { left: 77.5%; top: 50%; transform: rotateZ(-17deg); } // pin-2
                46%     { left: 76.5%; top: 49%; transform: rotateZ(-135deg); }
                50%     { left: 62.5%; top: 29%; transform: rotateZ(-135deg); }
                51%     { left: 63.5%; top: 29%; transform: rotateZ(-10deg); }
                55%     { left: 70%; top: 26%; transform: rotateZ(-10deg); } // pin-3
                56%     { left: 68.5%; top: 25%; transform: rotateZ(-140deg); }
                60%     { left: 54.5%; top: 7%; transform: rotateZ(-140deg); }
                61%     { left: 55.5%; top: 8%; transform: rotateZ(-13deg); }
                65%     { left: 65.5%; top: 2%; transform: rotateZ(-13deg); }
                66%     { left: 65.5%; top: 3%; transform: rotateZ(17deg); }
                70%     { left: 70.5%; top: 6%; transform: rotateZ(17deg); }
                71%     { left: 70.5%; top: 6%; transform: rotateZ(47deg); }
                75%     { left: 75%; top: 12%; transform: rotateZ(47deg); }
                76%     { left: 75%; top: 12%; transform: rotateZ(-13deg); }
                80%     { left: 83%; top: 7%; transform: rotateZ(-13deg); }
                81%     { left: 83%; top: 9%; transform: rotateZ(63deg); }
                85%     { left: 86%; top: 17%; transform: rotateZ(58deg); }
                86%     { left: 86.5%; top: 17%; transform: rotateZ(-7deg); }
                90%     { left: 97%; top: 13%; transform: rotateZ(-7deg); }
                91%     { left: 96%; top: 11%; transform: rotateZ(-111deg); }
                95%     { left: 94.5%; top: -3%; transform: rotateZ(-70deg); } // pin-4
                100%    { left: 94.5%; top: -3%; transform: rotateZ(-70deg); }
            }

            .nft-pin {
                position: absolute;
                width: auto;
                opacity: 0;

                &.pin-1 {
                    left: 29%;
                    top: 75%;
                    height: 11.4%;
                    animation: pin-1 15s 2s infinite ease;
                }

                @keyframes pin-1 {
                    0%      { opacity: 0; }
                    15%     { opacity: 0; }
                    16%     { opacity: 1; }
                    100%    { opacity: 1; }
                }

                &.pin-2 {
                    left: 73%;
                    top: 38%;
                    height: 14.85%;
                    animation: pin-2 15s 2s infinite ease;
                }

                @keyframes pin-2 {
                    0%      { opacity: 0; }
                    45%     { opacity: 0; }
                    46%     { opacity: 1; }
                    100%    { opacity: 1; }
                }

                &.pin-3 {
                    left: 70%;
                    top: 13%;
                    height: 15.8%;
                    animation: pin-3 15s 2s infinite ease;
                }

                @keyframes pin-3 {
                    0%      { opacity: 0; }
                    55%     { opacity: 0; }
                    56%     { opacity: 1; }
                    100%    { opacity: 1; }
                }

                &.pin-4 {
                    left: 89.5%;
                    top: -31%;
                    height: 29.3%;
                    animation: pin-4 15s 2s infinite ease;
                }

                @keyframes pin-4 {
                    0%      { opacity: 0; }
                    95%     { opacity: 0; }
                    96%     { opacity: 1; }
                    100%    { opacity: 1; }
                }
            }
        }
    }

    @media (min-width: 768px) {
        & > .container-xl {
            height: 670px;
        }

        .sec-header {
            margin-top: 8rem;
        }

        .locanft-maps-wrapper {
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    @media (max-width: 767px) {
        .locanft-maps-wrapper {
            .locanft-maps-animation {
                .locanft-maps-bg {
                    margin-left: -20%;
                    margin-right: -45%;
                }
            }

            .locanft-maps-elements {
                top: 30%;
                left: 6%;
                right: 2%;
            }
        }
    }
}