.page-tokenomics {
    .header-scroll-down {
        top: 20rem;
    }

    @media (max-width: 767px) {
        .header-scroll-down {
            display: none;
        }
    }
}

.tokenomics-page-header {
    .sec-header {
        @media (min-width: 768px) {
            margin-bottom: 12rem;
        }
    }

    h3 {
        font-size: 2rem;
    }
}

.tokenomics-info-section {
    .tokenomics-section-overlay {
        z-index: 1;
        mix-blend-mode: screen;
        top: -12%;
        bottom: -20%;
        left: -40%;
        right: -35%;
        background-size: auto 100%;
    }

    .container-xl {
        position: relative;
        z-index: 2;
        
        @media (min-width: 768px) {
            margin-top: -14rem;
        }
    }
    
    .token-info-row {
        padding: 4.75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: linear-gradient(90deg, rgba(58, 161, 236, 0.20) 0%, rgba(38, 188, 174, 0.20) 100%);
        backdrop-filter: blur(10px);

        h6 {
            font-weight: 500;
        }

        p {
            font-size: 2.25rem;
            font-weight: 500;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        
        @media (max-width: 767px) {
            padding: 3rem 2.5rem;
        }
    }

    .tokenomics-graph {
        margin-top: 8rem;
    }
}

.tokenomics-discover-section {
    padding-top: 2rem;

    .section-bg {
        background-size: contain;
        top: 14rem;
        bottom: 14rem;
    }

    .sec-content {
        position: relative;
        
        @media (min-width: 768px) {
            padding-top: 110px;
        }
    }

    .discover-img {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 100px;
        right: 0;
        left: 50%;

        img {
            height: 100%;
            width: auto;
            object-fit: contain;
        }
    }

    .discover-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 1.5rem;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(10px);
    }

    .discover-body {
        padding: 12rem 3.75rem;
        overflow: hidden;
    }

    .discover-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        border-radius: 0rem 0rem 1.5rem 1.5rem;
        background: rgba(255, 255, 255, 0.10);
        box-shadow: 0px -1px 16px 0px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(100px);
        padding: .75rem 3.75rem;

        p {
            font-weight: 600;
            letter-spacing: .2rem;
            margin: 0;
            text-transform: uppercase;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.92) 50%, #B2B2B2 76.19%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        img {
            width: auto;
            height: 48px;
        }
    }

    @media (max-width: 767px) {
        .discover-body {
            padding: 2.75rem .75rem;

            img {
                margin-bottom: -96px;
            }
        }

        .discover-footer {
            padding: .75rem;

            p {
                font-size: .75rem;
            }

            img {
                width: auto;
                height: 24px;
            }
        }
    }
}